/* Theme Name: Desi blue Theme**/
a.thumbnail:hover {
    -moz-box-shadow: 0 1px 4px #3399CC;
    -webkit-box-shadow: 0 1px 4px #3399CC;
    border-color: #3399CC;
    box-shadow: 0 1px 4px #3399CC;
}

a.thumbnail:hover {
    -moz-box-shadow: 0 1px 4px #3399CC;
    -webkit-box-shadow: 0 1px 4px #3399CC;
    box-shadow: 0 1px 4px #3399CC;
}

.underline {
    border-bottom: 1px solid #3399CC;
}

.exclamation .underline {
    border-bottom: 2px solid #3399CC;
}

input:focus,
select:focus,
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #3399CC;
    /* IE6-9 */
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #3399CC;
    border-color: #3399CC;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #3399CC;
}

.outer-nav {
    background-color: #54a4cd;
    border-bottom: 2px solid #3399CC;
}

footer {
    background-color: #54a4cd;
    border-top: 2px solid #3399CC;
}

h2 .label, 
h3 .label,
ul.menu > li.active a,
ul.menu > li:hover a {
    background-color:#3399CC;
}

a,.colored,
.underline a:hover,
.single-item a:hover, 
.page-stats li span.stat-title {
    color: #3399CC;
}

.single-item span.date, 
.post-img-date, 
ul.menu ul.sub-menu li a:hover, 
ul.menu ul.sub-menu li.active a {
    background-color: #3399CC;
}

ul.menu li a, 
.single-item span.date {
    text-shadow: 0 2px 0 #3399CC;
}

 @media (min-width: 980px) {
    ul.menu ul.sub-menu li a {
    background-color: #54a4cd;
    }
}

